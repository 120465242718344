import Vue from 'vue/dist/vue.esm.js';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import toastr from 'toastr';
import VueRecaptcha from 'vue-recaptcha';
import VueYandexMetrika from 'vue-yandex-metrika';

import config from './config.js';
const { baseUrl, lkUrl, recaptchaKey, meticaId } = config;

Vue.use(Vuelidate);

Vue.use(VueYandexMetrika, {
  id: meticaId,
  env: process.env.NODE_ENV,
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
  },
});

import { required, minLength } from 'vuelidate/lib/validators';

import slides from './slides.js';
import getUrlParams from './getUrlParams.js';
import initSwiper from './initSwiper.js';
import renderSlides from './renderSlides.js';
import Modal from './Modal.js';
import Checkbox from './Checkbox.js';

const phoneNumber = value => /^(([+]?\d{1,3}|\d{1,3})?\s*([(]{1}\s*[\d]{3}[)]{1}|\d{3})\s*(\d{3}\s*[-]?\s*\d{2}\s*[-]?\s*\d{2}))?$/.test(value);
const emailValidator = value => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);

const vm = new Vue({
  el: '#app',
  components: {
    Modal,
    Checkbox,
    VueRecaptcha,
  },
  data() {
    return {
      recaptchaKey,
      isDesktopChecked: true,
      isMobileChecked: true,
      mySwiper: null,
      activeSlide: 1,
      menuShown: false,
      loading: false,
      login: '',
      password: '',
      name: '',
      last_name: '',
      message: '',
      email: '',
      phone: '',
      formErr: {},
      submitted: false,
      isLoginModalShown: false,
      isRegisterModalShown: false,
      isResetModalShown: false,
      isApplicationModalShown: false,
      recaptchaResponse: '',
      rules_accepted: false,
      offer_accepted: false,
      selectedSlider: 'desktop',
    };
  },
  validations: {
    login: {
      required,
      minLength: minLength(3),
    },
    email: {
      required,
      email: emailValidator,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    phone: {
      phoneNumber,
    },
  },
  computed: {
    slides() {
      return slides.filter(el => {
        return el.groups.includes(this.selectedSlider);
      });
    },
    slidesLegend() {
      return this.slides.reverse();
    }
  },
  watch: {
    slides(newVal) {
      renderSlides(this.mySwiper, this.slides);
      this.slideTo(0);
    },
    isLoginModalShown(newVal) {
      if (!newVal) {
        this.clearForms();
      }
    },
    isRegisterModalShown(newVal) {
      if (!newVal) {
        this.clearForms();
      }
    },
    isResetModalShown(newVal) {
      if (!newVal) {
        this.clearForms();
      }
    },
    isApplicationModalShown(newVal) {
      if (!newVal) {
        this.clearForms();
      }
    },
  },
  methods: {
    slideTo(slideId) {
      this.mySwiper.slideToLoop(slideId);
      this.activeSlide = this.mySwiper.realIndex;
    },
    submitApplicationForm() {
      const {
        name,
        last_name,
        phone,
        email,
        message,
        recaptchaResponse,
      } = this;
      if (email.length > 0) {
        axios({
          method: 'post',
          url: `${baseUrl}/application/`,
          data: {
            name,
            last_name,
            ...(phone && { phone }),
            email,
            message,
            recaptchaResponse
          },
        }).then(
          (resp) => {
            this.loading = false;
            toastr.success('Письмо с инструкциями отправленно на ваш email', 'Успешно!');
            this.isApplicationModalShown = false;
          },
          (error) => {
            this.formErr = error.response.data.error.message;
            this.loading = false;

            toastr.error('Произошла ошибка, проверьте правильность ввода', 'Ошибка!');
          },
        );
      } else {
        toastr.warning('Пожалуйста, введите email', 'Внимание');
      }

    },
    submitResetForm() {
      const { email } = this;
      if (email.length > 0) {
        axios({
          method: 'post',
        url: `${baseUrl}/auth/password/forgot/`,
          data: { email }
        }).then(
          (resp) => {
            this.loading = false;
            toastr.success('Письмо с инструкциями отправленно на ваш email', 'Успешно!');
            this.isResetModalShown = false;
          },
          (error) => {
            this.formErr = error.response.data.error.message;
            if (error.response.status === 400) {
              toastr.error('Произошла ошибка, пользователь с таким email не найден', 'Ошибка!');
            } else {
              toastr.error('Произошла ошибка, проверьте правильность ввода', 'Ошибка!');
            }
          },
        );
      } else {
        toastr.warning('Пожалуйста, введите email', 'Внимание');
      }
    },
    submitLoginForm() {
      this.submitted = true;
      this.formErr = {};

      this.$v.$touch();
      const { login, password } = this;

      axios({
        method: 'post',
        url: `${baseUrl}/auth/token/`,
        data: {
          login,
          password,
        }
      }).then(
        (resp) => {
          this.loading = false;
          window.location.href = `${lkUrl}/login?token=${resp.data.token}`
        },
        (error) => {
          console.log({ error });
          this.formErr = error.response.data.error.message;
          this.loading = false;
        },
      );
    },
    submitRegisterForm() {
      this.submitted = true;
      this.formErr = {};

      this.$v.$touch();

      if (this.$v.$invalid) return;

      if (!this.loading) {
        this.loading = true;
        const {
          login,
          password,
          email,
          phone,
        } = this;

        axios({
          method: 'post',
          url: `${baseUrl}/auth/registration/`,
          data: {
            login,
            password,
            email,
            ...(phone && { phone }),
          }
        }).then(
          (resp) => {
              const target = 'registration';
              this.$metrika.reachGoal(target);
              this.loading = false;
              window.location.href = `${lkUrl}/login?token=${resp.data.token}`
          },
          (error) => {
            console.log(error.response.data);
            this.formErr = error.response.data.error.message;
            this.loading = false;
          },
        );
      }
    },
    clearForms() {
      this.email = '';
      this.name = '';
      this.last_name = '';
      this.login = '';
      this.message = '';
      this.password = '';
      this.phone = '';
      this.formErr = {};
      this.submitted = false;
      this.$v.$reset();
    },
  },
  mounted() {
    this.mySwiper = initSwiper();
    this.mySwiper.on('slideChange', () => {
      this.activeSlide = this.mySwiper.realIndex;
    });

    renderSlides(this.mySwiper, this.slides);
    this.slideTo(0);
  },
});

export default vm;
