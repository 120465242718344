import Vue from 'vue/dist/vue.esm.js';

const template = `
  <div id="modal">
    <transition name="modal">
      <div class="modal-mask" v-if="shown">
        <div class="modal-wrapper">
          <div class="modal-underlay" @click="!unclosable && $emit('update:shown', false)"></div>
          <div class="modal-container">
            <button v-if="!unclosable"
              class="modal-close-btn"
              @click="$emit('update:shown', false)"
            ></button>
            <div class="modal-header">
              <slot name="header">
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button @click="$emit('update:shown', false)">
                  OK
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
`;


export default Vue.component('Modal', {
  name: 'Modal',
  template,
  props: {
    shown: {
      type: Boolean,
      default: false,
      required: true,
    },
    unclosable: {
      type: Boolean,
      default: false,
    },
  },
});