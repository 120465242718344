import Swiper from 'swiper';

function initSwiper() {
  return new Swiper ('.partners-block .swiper-container', {
    slidesPerView: 2.5,
    spaceBetween: 30,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      992: {
        slidesPerView: 4,
      } 
    }
  });
}

export default initSwiper;
