export default [
	{
		title: 'Pre-Roll',
		text: 'Рекламный ролик, размещенный в плеере перед основным контентом',
		example: '/static/img/formats_mob_preroll.jpg',
		groups: ['mobile'],
	},
	{
		title: 'Content-roll',
		text: 'Рекламный ролик появляется автоматически <br> в текстово-графическом контенте между абзацами <br> при скролле страницы пользователем.',
		example: '/static/img/formats_mob_contentroll.jpg',
		groups: ['mobile'],
	},

	{
		title: 'Pre-Roll',
		text: 'Рекламный ролик, размещенный в плеере перед основным контентом',
		example: '/static/img/formats_desk_preroll.jpg',
		groups: ['desktop'],
	},
	{
		title: 'Content-roll',
		text: 'Рекламный ролик появляется автоматически <br> в текстово-графическом контенте между абзацами <br> при скролле страницы пользователем.',
		example: '/static/img/formats_desk_contentroll.jpg',
		groups: ['desktop'],
	},
	{
		title: 'Over-roll',
		text: 'Формат рекламного ролика, при котором весь контент сайта затемняется на время показа ролика, гарантируя brand safety для рекламодателей и не позволяет отвлекать пользователя баннерами и прочим в зоне видимости плеера',
		example: '/static/img/formats_desk_overroll.jpg',
		groups: ['desktop'],
	},
	{
		title: 'Pause-roll',
		text: 'Формат рекламного ролика, его показ осуществляется при постановке видео-контента на паузу',
		example: '/static/img/formats_desk_pauseroll.jpg',
		groups: ['desktop'],
	},
	{
		title: 'Post-roll',
		text: 'Формат рекламного ролика, показ которого осуществляется после основного видео контента',
		example: '/static/img/formats_desk_postroll.jpg',
		groups: ['desktop'],
	},
];