function renderSlides(formats, slides) {
  formats.removeAllSlides();

  formats.addSlide(0, slides.reduce((acc, current) => {
    return [
      ...acc,
      `
      <div class="swiper-slide">
        <div class="d-lg-none">
          <h3>${current.title}</h3>
          <div class="slide-content">${current.text}</div>
        </div>
        <div class="d-none d-lg-block container laptop__wrapper">
          <div class="row">
            <div class="col-7">
              <div class="${current.groups[0]==='desktop'?'laptop':'mobile'}"><img src="${current.example}" alt="${current.title}" /></div>
            </div>
            <div class="col-4 pt-lg-4">
              <h3>${current.title}</h3>
              <div class="slide-content">${current.text}</div>
            </div>
          </div>
        </div>
      </div>
      `,
    ];
  },[]).reverse());

  formats.update();
}

export default renderSlides;