
const productionConfig = {
  baseUrl: 'https://api.utraff.com/api/lk',
  lkUrl: 'https://lk.utraff.com',
  recaptchaKey: '6LdelZgUAAAAAM0lRU2o-UnTrvoiE-ZzQHnQQTRI',
  meticaId: 57180682,
};

const developmentConfig = {
  baseUrl: 'https://api.utraff.info/api/lk',
  lkUrl: 'https://lk.utraff.info',
  recaptchaKey: '6LdelZgUAAAAAM0lRU2o-UnTrvoiE-ZzQHnQQTRI',
  meticaId: 57162979,
};

const resultConfig = {
  ...productionConfig,
  ...(process.env.NODE_ENV === 'development' && developmentConfig),
};

export default resultConfig;
