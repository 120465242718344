import Vue from 'vue/dist/vue.esm.js';

const template = `
  <div class="checkbox"
    :class="{'checkbox--checked': val}"
    @click="clickEvt"
  >
    <slot />
  </div>
`;


export default Vue.component('Modal', {
  name: 'Checkbox',
  template,
  props: {
    val: [Boolean, Number, null],
  },
  methods: {
    clickEvt(evt) {
      if (evt.target.localName === 'a') return;
      this.$emit('update:val', !this.val);
    },
  },
});