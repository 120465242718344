import Swiper from 'swiper';

function initSwiper(slides) {
  const formsts = new Swiper ('.formats-block .swiper-container.formats', {
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
  });

  return formsts;
}

export default initSwiper;
